/* frontend/src/styles/MainThemes.css */

/* Light theme */
.light-theme {
  --background-color: #e6ecfd;
  --text-color: #333;
  --header-background-color: #c0cfff;
  --navbar-background-color: #bbb2ff;
  --navbar-hover-background-color: #b39eff95;
  --footer-bg-color: #ad96ff;
  --logo-height: 80px;
  --header-text-color: #000000;
  --border-color: #c0c0c0;
  --button-bg-color: #7969b3;
  --button-text-color: #ffffff;
  --button-hover-bg-color: #ad96ff;
  --button-active-bg-color: #8261fc;
  --button-hover-text-color: #3700ff;
  --input-border-color: #ccc;
  --label-color: #000;
  --footer-text-color: #000000;
  --itemlist-bg-color: #c0cfff;
  --itemlist-selected-bgcolor: #bbb2ff;
  --itemlist-item-hover: #ad96ff;
}

/* Dark theme */
.dark-theme {
  --background-color: #333; /* Background color of the main page */
  --text-color: #f4f4f4; /* Primary text color */
  --header-background-color: #555; /* Header background */
  --navbar-background-color: #222; /* Navbar background */
  --navbar-hover-background-color: #666; /* Navbar hover effect */
  --footer-bg-color: #444; /* Footer background */
  --logo-height: 80px; /* Keep the same logo height */
  --header-text-color: #ddd; /* Header text color */
  --border-color: #444; /* Borders around elements */
  --button-bg-color: #444; /* Button background */
  --button-text-color: #f4f4f4; /* Button text color */
  --button-hover-bg-color: #555; /* Button hover background */
  --button-active-bg-color: #666; /* Active button background color */
  --button-hover-text-color: #f4f4f4; /* Button hover text color */
  --input-border-color: #777; /* Input field border */
  --label-color: #ddd; /* Form labels text color */
  --footer-text-color: #ddd; /* Footer text color */
  --itemlist-bg-color: #444; /* Background color for list items */
  --itemlist-selected-bgcolor: #555; /* Background color when a list item is selected */
  --itemlist-item-hover: #666; /* Background color when hovering over a list item */
}
