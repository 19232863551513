/* frontend/src/pages/HomePage.css */

body {
  background-color: var(--background-color);
}

.container {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  justify-content: center; /* Center the boxes horizontally */
}

.box {
  width: 300px;
  height: 300px;
  margin: 10px;
  background-color: var(--box-bg-color);
  border: 2px solid var(--border-color);
}
