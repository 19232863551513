/* frontend/src/styles/NavBar.css */

.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: -20px;
    border-radius: 10px;
  }
  
  .navbar {
    background-color: var(--navbar-background-color);
    display: flex;
    align-items: center;
  }
  
  .navbar ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }
  
  .navbar ul li {
    margin-left: 20px;
    position: relative;
  }
  
  .navbar ul li a {
    display: block;
    padding: 0rem;
    color: var(--header-text-color);
    text-decoration: none;
  }
  
  .navbar ul li a:hover {
    background-color: var(--navbar-hover-background-color);
    color: var(--button-hover-text-color);
  }
  
  .navbar ul ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--navbar-background-color);
    z-index: 999;
  }
  
  .navbar ul ul li {
    width: 200px;
  }
  
  .navbar ul ul li a {
    padding: 0.5rem 1rem;
  }
  
  .navbar ul li:hover > ul {
    display: block;
  }
  
  .navbar-search {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  
  .navbar-search input {
    padding: 0.5rem;
    border: 1px solid var(--navbar-hover-background-color);
    border-radius: 5px;
    margin-right: 5px;
    background-color: var(--background-color);
  }
  
  .navbar-search button {
    padding: 0.5rem 1rem;
    background-color: var(--navbar-background-color);
    border: 1px solid var(--navbar-hover-background-color);
    border-radius: 5px;
    cursor: pointer;
  }
  
  .navbar-search button:hover {
    background-color: var(--navbar-hover-background-color);
    border: 1px solid #fff;
    border-radius: 5px;
    color: var(--button-hover-text-color);
  }
  