/* frontend/src/styles/InputForm.css */

/* Main form container */
.form-container {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
  border-radius: 0px;
  /* width: 95%; */
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  height: 70vh;
}

.form-item {
  padding: 10px;
  box-sizing: border-box;
}

/* First item */
.form-item:first-child {
  width: 100%;
  text-align: center;
  margin-top: -40px;
}

/* Form item fields */
.form-item-field {
  flex-basis: 70%;
  padding: 20px;
  border: 1px solid var(--border-color);
}

/* Buttons section */
.form-item-buttons {
  flex-basis: 20%;
  margin-left: 4%;
  padding: 20px;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-container {
  border: 1px solid var(--border-color);
  width: 58%;
  min-width: 420px;
  padding: 20px;
  height: 80%;
  border-radius: 5px;
}

.second-container {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-left: 10px;
  max-height: 100%;
  width: 20%;
  padding: 20px;
  height: 80%;
  min-width: 150px;
}

.third-container {
  border: 1px solid var(--border-color);
  margin-left: 5px;
  min-width: 150px;
  overflow: auto;
  height: 90%;
}

.form-button-container {
  border: 1px solid var(--border-color);
  width: 10%;
  min-width: 90px;
  padding: 20px;
  height: 80%;
  border-radius: 5px;
  margin-left: 10px;
}

.command-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.command-button button {
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.command-button button:hover {
  background-color: var(--button-hover-bg-color);
}

.command-button button:active {
  background-color: #0d47a1;
}

.command-button button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.command-button button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(25, 118, 210, 0.5);
}


.full-width-header {
  background-color: var(--navbar-background-color);
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: -30px;
  font-size: medium;
  text-align: center;
  border-radius: 5px;
}

/* input textbox for dark mode */
.input-dark-mode {
  color: var(--text-color);
  background-color: #555;
}

.input-dark-mode .MuiOutlinedInput-input {
  color: #f4f4f4;
  /* Creamy white for input text */
}

.input-dark-mode .MuiOutlinedInput-root fieldset {
  border-color: var(--border-color);
}

.input-dark-mode .MuiOutlinedInput-root:hover fieldset {
  border-color: var(--button-hover-bg-color);
}

.input-dark-mode .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: var(--button-hover-bg-color);
}

.input-dark-mode .MuiInputLabel-root {
  color: var(--label-color);
}

.input-dark-mode .MuiInputLabel-root.Mui-focused,
.input-dark-mode .MuiInputLabel-root.MuiInputLabel-shrink {
  color: rgb(156, 207, 214);
}

.gsdlist-list {
  padding: 10px;
}

.gsdlist-item {
  background-color: var(--itemlist-bg-color);
  color: var(--text-color);
  cursor: pointer;
  padding: 8px;
  margin-bottom: 5px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.gsdlist-item:hover {
  background-color: var(--itemlist-item-hover);
}

.gsdlist-item.selected {
  background-color: var(--itemlist-selected-bgcolor);
  color: var(--text-color);
}
