/* frontend/src/styles/MyLogin.css */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.login-box {
    width: 300px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}


.logo {
    width: 100px;
    /* Adjust the width as needed */
    height: auto;
    /* This maintains the aspect ratio */
    display: block;
    margin: 0 auto 20px;
    /* Centers the logo and adds some space below it */
}

.company-name {
    text-align: center;
    font-size: 1.5em;
    margin: 10px 0;
    color: #333;
}

.company-address,
.company-slogan {
    text-align: center;
    font-size: 1em;
    margin: 5px 0;
    color: #777;
}

.input-container {
    position: relative;
    margin: 20px 0;
}

.icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #777;
}

.input {
    width: 100%;
    padding: 10px 10px 10px 40px; /* Adjust padding to fit the icon */
    border: 1px solid #ccc;
    border-radius: 25px;
    outline: none;
    transition: border-color 0.3s;
    box-sizing: border-box;
}

.input:focus {
    border-color: #007bff;
}

.placeholder {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    color: #777;
    pointer-events: none;
    transition: all 0.3s;
    z-index: 2;
}

.input.has-value + .placeholder,
.input:focus + .placeholder {
    top: -5px; /* Adjust to move the label higher */
    left: 40px;
    font-size: 12px;
    color: #007bff;
    background-color: #fff;
    padding: 0 5px;
}

.eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.sign-in-button, .sign-up-button {
    width: 98%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.sign-in-button {
    background-color: #001aff; /* Green background */
    color: white; /* White text */
}

.sign-in-button:hover {
    background-color: #283eff; /* Darker green on hover */
}

.sign-up-link {
    text-align: center;
    font-size: 14px;
}

.sign-up-link a {
    color: #008CBA; /* Blue text */
    text-decoration: none;
}

.sign-up-link a:hover {
    text-decoration: underline;
}