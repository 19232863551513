/* frontend/src/styles/RibbonButton.css */

.ribbon-button {
    display: flex;
    align-items: center;
    padding: 1px;
    font-size: 10px;
    background-color: var(--header-background-color); 
    color: var(--text-color);
    border: 1px solid var(--border-color);
    border-top: none; /* Remove the top border */
    border-left: none; /* Remove the left border */
    border-right: none; /* Remove the right border */
    border-bottom: 1px solid var(--border-color); /* Keep only the bottom border */
    border-bottom-left-radius: 10px; /* Rounded bottom-left corner */
    border-bottom-right-radius: 10px; /* Rounded bottom-right corner */
    margin-left: 5px;
}

  
  .ribbon-button:hover {
    color: var(--button-hover-text-color);
    border: 1px solid #fff;
  }
  