/* frontend/src/styles/HeaderFooter.css */

.mainheader {
  padding: 5px 5px;
  background-color: var(--header-background-color);
  color: var(--header-text-color);
  border-bottom: 1px solid var(--border-color);
  position: relative;
}

/* Second line with the logo, title, and user profile */
.second-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -20px;
}

.header-left {
  flex: 0 0 20%;
  text-align: left;
}

.header-left .logo-img {
  height: var(--logo-height);
}

.header-center {
  flex: 0 0 60%;
  text-align: center;
}

.header-center h1, .header-center h4, .header-center p {
  margin: 0;
  color: var(--header-text-color); /* Use theme variable */
}

.header-right {
  flex: 0 0 20%;
  display: flex;
  align-items: center;
}


/* Footer styling */
.footer {
  padding: 5px;
  background-color: var(--footer-bg-color); /* Use theme variable */
  color: var(--footer-text-color); /* Use theme variable */
  text-align: center;
  position: fixed;
  width: 100%;
  bottom: 0;
}
